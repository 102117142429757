// import React from 'react';
import React, { useEffect, useRef } from 'react';


export const Card = ({ children, title }) => (
  <div style={{ backgroundColor: '#1F2937', padding: '1rem', borderRadius: '0.5rem', marginBottom: '1rem' }}>
    <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>{title}</h2>
    {children}
  </div>
);

export const FilterButton = ({ label, isActive, onClick }) => (
  <button
    onClick={onClick}
    style={{
      padding: '0.5rem 1rem',
      borderRadius: '0.25rem',
      backgroundColor: isActive ? '#3B82F6' : '#4B5563',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
      marginRight: '0.5rem',
      marginBottom: '0.5rem'
    }}
  >
    {label}
  </button>
);

export const Modal= ({ isOpen, onClose, children }) =>  {
    const modalRef = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
        }

        // Attach the listener to the document
        if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
        }

        // Clean up the listener
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        }}>
        <div ref={modalRef} style={{
            backgroundColor: '#111827',
            padding: '2rem',
            borderRadius: '0.5rem',
            maxWidth: '80%',
            maxHeight: '80%',
            overflow: 'auto'
        }}>
             <button onClick={onClose} style={{float: 'right', padding: '0.5rem', backgroundColor: '#4B5563', color: 'white', border: 'none', borderRadius: '0.25rem'}}>Close</button>
            {children}
        </div>
        </div>
    );
}

// export const Modal = ({ isOpen, onClose, children }) => {
//   if (!isOpen) return null;
//   return (
//     <div style={{
//       position: 'fixed',
//       top: 0,
//       left: 0,
//       right: 0,
//       bottom: 0,
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center'
//     }}>
//       <div style={{
//         backgroundColor: '#1F2937',
//         padding: '2rem',
//         borderRadius: '0.5rem',
//         maxWidth: '80%',
//         maxHeight: '80%',
//         overflow: 'auto'
//       }}>
//         <button onClick={onClose} style={{float: 'right', padding: '0.5rem', backgroundColor: '#4B5563', color: 'white', border: 'none', borderRadius: '0.25rem'}}>Close</button>
//         {children}
//       </div>
//     </div>
//   );
// };