import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ErrorBar, ReferenceArea, ComposedChart } from 'recharts';
import { Card, FilterButton, Modal } from './CustomComponents';

// Extended mock data for demonstration (imagine this has 100 entries)
const mockData = Array.from({ length: 100 }, (_, i) => ({
  id: i + 1,
  address: `${i + 1} Main St`,
  current_price: Math.floor(Math.random() * 500000) + 300000,
  beds: Math.floor(Math.random() * 3) + 2,
  baths: Math.floor(Math.random() * 2) + 1,
  sqft: Math.floor(Math.random() * 1000) + 1000,
  status: ['For Sale', 'Pending', 'Sold'][Math.floor(Math.random() * 3)],
  city: ['New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix'][Math.floor(Math.random() * 5)],
  state: ['NY', 'CA', 'IL', 'TX', 'AZ'][Math.floor(Math.random() * 5)],
  priceHistory: Array.from({ length: 12 }, (_, j) => ({
    date: `2023-${(j + 1).toString().padStart(2, '0')}`,
    price: Math.floor(Math.random() * 50000) + 300000
  })),
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  images: ["/api/placeholder/400/300", "/api/placeholder/400/300"]
}));

function App() {
  return (
    <Dashboard />
  );
}

function Dashboard() {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({ cities: [], states: [], statuses: [] });
  const [selectedListings, setSelectedListings] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scrapeUrl, setScrapeUrl] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  // For connection with telegram
  const [telegramUrls, setTelegramUrls] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [connectionError, setConnectionError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(null);
  const [newNote, setNewNote] = useState('');

  const [isAddNoteHovered, setIsAddNoteHovered] = useState(false);
  const [isClearNoteHovered, setIsClearNoteHovered] = useState(false);
  const [isClearNotesModalOpen, setIsClearNotesModalOpen] = useState(false);
  const [clearNotesListing, setClearNotesListing] = useState(null);

  // const [filters, setFilters] = useState({ cities: [], states: [], statuses: [] });
  // const [selectedListings, setSelectedListings] = useState([]);
  // const [selectedListing, setSelectedListing] = useState(null);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const openClearNotesModal = (listing) => {
    setClearNotesListing(listing);
    setIsClearNotesModalOpen(true);
  };

  const closeClearNotesModal = () => {
    setIsClearNotesModalOpen(false);
    setClearNotesListing(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedListing(null);
    setNewNote('');
  };

  // Comment
  const handleAddNote = async () => {
    if (!newNote.trim() || !selectedListing) return;

    try {
      const response = await axios.post('https://houseyhousey.me/scraper/api/property/addNote', {
        propertyId: selectedListing.id,
        note: newNote
      });

      if (response.data.message === 'Note added successfully') {
        const updatedListing = await fetchUpdatedListing(selectedListing.id);
        if (updatedListing) {
          setSelectedListing(updatedListing);
          setProperties(prevProperties => 
            prevProperties.map(prop => 
              prop.id === updatedListing.id ? updatedListing : prop
            )
          );
        }
        setNewNote('');
      } else {
        console.log('Data not success: ', response.data);
      }
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  // const handleAddNote = async () => {
  //   console.log('Selected ID: ', selectedListing);
  //   if (!newNote.trim() || !selectedListing) return;

  //   try {
  //     const response = await axios.post('http://localhost:3001/api/property/addNote', {
  //       propertyId: selectedListing.id,
  //       note: newNote
  //     });

  //     if (response.data.message == 'Note added successfully') {
  //       // Update the selectedListing with the new note
  //       setSelectedListing(prevListing => ({
  //         ...prevListing,
  //         notes: [...(prevListing.notes || []), ', ' + newNote]
  //       }));
  //       setNewNote(''); // Clear the input
  //       console.log(selectedListing.notes);
  //     }
  //     else {
  //       console.log('Data not success: ', response.data);
  //     }
  //   } catch (error) {
  //     console.error('Error adding note:', error);
  //     // Handle error (e.g., show an error message to the user)
  //   }
  // };

  // const handleClearNotes = async () => {
  //   if (!clearNotesListing) {
  //     console.error('No listing selected for clearing notes');
  //     return;
  //   }

  //   try {
  //     const response = await axios.delete(`http://localhost:3001/api/property/${clearNotesListing.id}/notes`);
  //     if (response.data.success) {
  //       // Update the selectedListing if it's the same as clearNotesListing
  //       if (selectedListing && selectedListing.id === clearNotesListing.id) {
  //         console.log('Respone is: ', response)
  //         setSelectedListing(prevListing => ({
  //           ...prevListing,
  //           notes: []
  //         }));
  //       }
  //       closeClearNotesModal();
  //     }
  //   } catch (error) {
  //     console.error('Error clearing notes:', error);
  //   }
  // };

  const handleClearNotes = async () => {
    if (!clearNotesListing) {
      console.error('No listing selected for clearing notes');
      return;
    }

    try {
      const response = await axios.delete(`https://houseyhousey.me/scraper/api/property/${clearNotesListing.id}/notes`);
      if (response.data.success) {
        const updatedListing = await fetchUpdatedListing(clearNotesListing.id);
        if (updatedListing) {
          if (selectedListing && selectedListing.id === clearNotesListing.id) {
            setSelectedListing(updatedListing);
          }
          setProperties(prevProperties => 
            prevProperties.map(prop => 
              prop.id === updatedListing.id ? updatedListing : prop
            )
          );
        }
        closeClearNotesModal();
      }
    } catch (error) {
      console.error('Error clearing notes:', error);
    }
  };

  useEffect(() => {
    console.log('Fetching properties');
    fetchProperties();
  }, []);

 
  useEffect(() => {
    let eventSource;

    const connectSSE = () => {
      console.log('Attempting to connect to Telegram backend...');
      eventSource = new EventSource('https://houseyhousey.me/bot/events');

      eventSource.onopen = (event) => {
        console.log('SSE connection opened', event);
        setIsConnected(true);
        setConnectionError(null);
      };

      eventSource.onmessage = (event) => {
        console.log('Received message from Telegram backend:', event.data);
        try {
          const data = JSON.parse(event.data);
          // if (data.added) {
          //   setTelegramUrls(prevUrls => [...prevUrls, data.added]);
          // } else if (data.removed) {
          //   setTelegramUrls(prevUrls => prevUrls.filter(url => url.id !== data.removed.id));
          // }
          if (data.added) {
            setTelegramUrls(prevUrls => {
              const newUrls = Array.isArray(data.added) ? data.added : [data.added];
              return [...prevUrls, ...newUrls];
            });
          } else if (data.removed) {
            setTelegramUrls(prevUrls => prevUrls.filter(url => url.id !== data.removed.id));
          }
        } catch (error) {
          console.error('Error parsing SSE message:', error);
        }
      };

      eventSource.onerror = (error) => {
        console.error('SSE connection error:', error);
        setIsConnected(false);
        setConnectionError('Failed to connect to Telegram backend. Retrying in 5 seconds...');
        eventSource.close();
        setTimeout(connectSSE, 5000);
      };
    };

    connectSSE();

    return () => {
      if (eventSource) {
        console.log('Closing SSE connection');
        eventSource.close();
      }
    };
  }, []);

  useEffect(() => {
    const processNextUrl = async () => {
      if (telegramUrls.length > 0 && !isProcessing) {
        setIsProcessing(true);
        const nextUrl = telegramUrls[0];
        setCurrentUrl(nextUrl);

        try {
          await handleScrape(nextUrl.url, nextUrl.id, nextUrl.chatId);
        } catch (error) {
          console.error('Error processing URL:', error);
        }

        // Remove the processed URL and wait for 4 seconds
        setTelegramUrls(prevUrls => prevUrls.slice(1));
        await new Promise(resolve => setTimeout(resolve, 4000 + Math.random() * 5000));

        setIsProcessing(false);
        setCurrentUrl(null);
      }
    };

    processNextUrl();
  }, [telegramUrls, isProcessing]);


  useEffect(() => {
    // Initial fetch
    fetchProperties();
    const randomWaitTime = Math.floor(Math.random() * 5) + 1; // Random wait time between 1 and 2 minutes

    console.log('Setting up interval for automatic refresh');
    // Set up interval for automatic refresh
    const intervalId = setInterval(() => {
      handleRefresh();
    }, (60 + randomWaitTime) * 60 * 1000); // 2 minutes in milliseconds
    console.log('Done up interval for automatic refresh');

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means this effect runs once on mount


  const fetchUpdatedListing = async (listingId) => {
    try {
      const response = await axios.get(`https://houseyhousey.me/scraper/api/property/${listingId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching updated listing:', error);
      return null;
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get('https://houseyhousey.me/scraper/api/properties');
      console.log('Fetched properties', response.data);
      setProperties(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch properties');
      setLoading(false);
    }
  };

  const handleScrape = async (url, urlId, chatId) => {
    if (!scrapeUrl && !url) {
      // alert('Please enter a URL to scrape');
      return;
    }
    try {
      console.log('Before starting to scrape');
      setLoading(true);
      const response = await axios.post('https://houseyhousey.me/scraper/api/scrape', { url: scrapeUrl?scrapeUrl:url });
      console.log('Response from server:', response.data);
      // alert('Scraping completed');
      console.log('Scraping completed');
      // Optionally, refresh the properties list here
      await fetchProperties();
      setScrapeUrl('');
      if (url && urlId && chatId) {
        await removeProcessedUrl(chatId, urlId);
      }
    } catch (err) {
      console.error('Error during scraping:', err);
      // alert('An error occurred during scraping');
    } finally {
      setLoading(false);
      console.log('Scraping process finished (success or failure)');
    }
  };

  const removeProcessedUrl = async (chatId, urlId) => {
    console.log(`Attempting to remove URL - chatId: ${chatId}, urlId: ${urlId}`);
    try {
      const response = await axios.post('https://houseyhousey.me/bot/remove-url', { chatId, urlId });
      console.log('Response from backend:', response.data);
      console.log('URL removed from backend');
    } catch (error) {
      console.error('Error removing URL from backend:', error.response ? error.response.data : error.message);
    }
  };

  const handleRefresh = async () => {
    try {
      setLoading(true);
      await axios.get('https://houseyhousey.me/scraper/api/refresh');
      // alert('Refresh completed');
      fetchProperties();
    } catch (err) {
      setError('Failed to refresh properties');
    } finally {
      setLoading(false);
    }
  };

  // const filteredData = useMemo(() => {
  //   return properties.filter(property => {
  //     return (
  //       (filters.cities.length === 0 || filters.cities.includes(property.city)) &&
  //       (filters.states.length === 0 || filters.states.includes(property.state)) &&
  //       (filters.statuses.length === 0 || filters.statuses.includes(property.status))
  //     );
  //   });
  // }, [properties, filters]);

  // const filteredData = useMemo(() => {
  //   // First, get the most recent entry for each unique address
  //   const latestProperties = Object.values(
  //     properties.reduce((acc, property) => {
  //       if (!acc[property.address] || new Date(property.timestamp) > new Date(acc[property.address].timestamp)) {
  //         acc[property.address] = property;
  //       }
  //       return acc;
  //     }, {})
  //   );
  
  //   // Then, apply filters to these latest properties
  //   return latestProperties.filter(property => {
  //     return (
  //       (filters.cities.length === 0 || filters.cities.includes(property.city)) &&
  //       (filters.states.length === 0 || filters.states.includes(property.state)) &&
  //       (filters.statuses.length === 0 || filters.statuses.includes(property.status))
  //     );
  //   });
  // }, [properties, filters]);

  const { filteredData, uniqueValues } = useMemo(() => {
    // Get the most recent entry for each unique address
    const latestProperties = properties.reduce((acc, property) => {
      if (!acc[property.address] || new Date(property.timestamp) > new Date(acc[property.address].timestamp)) {
        acc[property.address] = property;
      }
      return acc;
    }, {});
  
    // Create sets for unique cities, states, and statuses based on the latest data
    const cities = new Set();
    const states = new Set();
    const statuses = new Set();
  
    // Filter properties based on the most recent status, but keep all historical data
    const filteredData = properties.filter(property => {
      const latestProperty = latestProperties[property.address];
      
      // Add to unique sets
      cities.add(latestProperty.city);
      states.add(latestProperty.state);
      statuses.add(latestProperty.status);
  
      return (
        (filters.cities.length === 0 || filters.cities.includes(latestProperty.city)) &&
        (filters.states.length === 0 || filters.states.includes(latestProperty.state)) &&
        (filters.statuses.length === 0 || filters.statuses.includes(latestProperty.status))
      );
    });
  
    return {
      filteredData,
      uniqueValues: {
        cities: Array.from(cities),
        states: Array.from(states),
        statuses: Array.from(statuses)
      }
    };
  }, [properties, filters]);


  const toggleFilter = (type, value) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      if (newFilters[type].includes(value)) {
        newFilters[type] = newFilters[type].filter(item => item !== value);
      } else {
        newFilters[type] = [...newFilters[type], value];
      }
      return newFilters;
    });
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedListings([]);
    } else {
      const allListingIds = Array.from(new Set(filteredData.map(property => property.id)));
      setSelectedListings(allListingIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectAll) {
      const allListingIds = Array.from(new Set(filteredData.map(property => property.id)));
      setSelectedListings(allListingIds);
    }
  }, [filteredData, selectAll]);


  // const uniqueValues = useMemo(() => {
  //   const cities = [...new Set(properties.map(property => property.city))];
  //   const states = [...new Set(properties.map(property => property.state))];
  //   const statuses = [...new Set(properties.map(property => property.status))];
  //   return { cities, states, statuses };
  // }, [properties]);

  // const uniqueValues = useMemo(() => {
  //   // Get unique properties (last entry for each address)
  //   const uniqueProperties = Object.values(
  //     properties.reduce((acc, property) => {
  //       acc[property.address] = property;
  //       return acc;
  //     }, {})
  //   );
  
  //   // Sort unique properties by timestamp in descending order
  //   uniqueProperties.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  
  //   // Extract unique cities, states, and statuses
  //   const cities = [...new Set(uniqueProperties.map(property => property.city))];
  //   const states = [...new Set(uniqueProperties.map(property => property.state))];
  //   const statuses = [...new Set(uniqueProperties.map(property => property.status))];
  
  //   return { 
  //     uniqueProperties,
  //     cities, 
  //     states, 
  //     statuses 
  //   };
  // }, [properties]);

  // const uniqueValues = useMemo(() => {
  //   // Get unique properties (last entry for each address)
  //   const uniqueProperties = Object.values(
  //     properties.reduce((acc, property) => {
  //       if (!acc[property.address] || new Date(property.timestamp) > new Date(acc[property.address].timestamp)) {
  //         acc[property.address] = property;
  //       }
  //       return acc;
  //     }, {})
  //   );
  
  //   // Extract unique cities, states, and statuses
  //   const cities = [...new Set(uniqueProperties.map(property => property.city))];
  //   const states = [...new Set(uniqueProperties.map(property => property.state))];
  //   const statuses = [...new Set(uniqueProperties.map(property => property.status))];
  
  //   return { 
  //     uniqueProperties,
  //     cities, 
  //     states, 
  //     statuses 
  //   };
  // }, [properties]);

  const openListingDetails = (listing) => {
    console.log('Opening listing details:', listing.image_paths, listing.id, listing.notes);
    setSelectedListing(listing);
    setIsModalOpen(true);
  };

  const toggleListingSelection = (id) => {
    setSelectedListings(prev => 
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  };

  const getColorByIndex = (index) => {
    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    return colors[index % colors.length];
  };

  const selectedListingsData = useMemo(() => {
    const selected = filteredData.filter(property => selectedListings.includes(property.id));
    
    console.log('Selected Listings:');
    selected.forEach(listing => {
      // console.log(`ID: ${listing.id}, Address: ${listing.address}, Price: ${listing.current_price}, Images: ${listing.image_paths}`);
    });
    
    console.log(`Total selected listings: ${selected.length}`);
    
    return selected;
  }, [filteredData, selectedListings]);

  // const averageData = useMemo(() => {
  //   const pricesByDate = {};
  //   filteredData.forEach(property => {
  //     property.priceHistory.forEach(history => {
  //       if (!pricesByDate[history.date]) {
  //         pricesByDate[history.date] = [];
  //       }
  //       pricesByDate[history.date].push(history.price);
  //     });
  //   });

  const averageData = useMemo(() => {
    const pricesByDate = {};
    const seenProperties = new Set();
  
    // Sort the filtered data by timestamp to ensure chronological order
    const sortedData = [...filteredData].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  
    sortedData.forEach(property => {
      const price = parseFloat(property.current_price.replace(/[$,]/g, ''));
      const date = new Date(property.timestamp).toLocaleDateString('en-US');
      
      // Only process this property if we haven't seen it before
      if (!seenProperties.has(property.address)) {
        if (!pricesByDate[date]) {
          pricesByDate[date] = [];
        }
        pricesByDate[date].push(price);
        seenProperties.add(property.address);
      }
    });
  
    console.log('Prices by date:', pricesByDate);
    
    return Object.entries(pricesByDate).map(([date, prices]) => {
      const avg = prices.reduce((sum, price) => sum + price, 0) / prices.length;
      const stdDev = Math.sqrt(prices.reduce((sum, price) => sum + Math.pow(price - avg, 2), 0) / prices.length);
      return { 
        date, 
        avgPrice: avg, 
        stdDev, 
        upperBound: avg + stdDev, 
        lowerBound: avg - stdDev,
        count: prices.length  // Added count of new listings per day
      };
    }).sort((a, b) => new Date(a.date) - new Date(b.date)); // Ensure the result is sorted by date
  }, [filteredData]);

  // const statusAverageData = useMemo(() => {
  //   const pricesByDateAndStatus = {};
  //   filteredData.forEach(property => {
  //     const price = parseFloat(property.current_price.replace(/[$,]/g, ''));
  //     if (!pricesByDateAndStatus[property.timestamp]) {
  //       pricesByDateAndStatus[property.timestamp] = {
  //         'For Sale': [],
  //         'Pending': [],
  //         'Sold': []
  //       };
  //     }
  //     pricesByDateAndStatus[property.timestamp][property.status].push(price);
  //   });

  //   return Object.entries(pricesByDateAndStatus).map(([date, statusPrices]) => {
  //     const result = { date };
  //     ['For Sale', 'Pending', 'Sold'].forEach(status => {
  //       const prices = statusPrices[status];
  //       if (prices.length > 0) {
  //         const avg = prices.reduce((sum, price) => sum + price, 0) / prices.length;
  //         const stdDev = Math.sqrt(prices.reduce((sum, price) => sum + Math.pow(price - avg, 2), 0) / prices.length);
  //         result[`${status}Avg`] = avg;
  //         result[`${status}StdDev`] = stdDev;
  //       }
  //     });
  //     return result;
  //   });
  // }, [filteredData]);

  // const priceHistoryData = useMemo(() => {
  //   return selectedListingsData.map(property => ({
  //     id: property.id,
  //     address: property.address,
  //     timestamp: new Date(property.timestamp).toLocaleDateString(),
  //     price: parseFloat(property.current_price.replace(/[$,]/g, ''))
  //   }));
  // }, [selectedListingsData]);

  const formatDate = (date) => {
    const d = new Date(date);
    return d;
    //${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  // const priceHistoryData = useMemo(() => {
  //   // Get the addresses of all selected listings
  //   const selectedAddresses = new Set(selectedListingsData.map(listing => listing.address));
  //   const result = filteredData
  //     .filter(property => selectedAddresses.has(property.address))
  //     .map(property => ({
  //       id: property.id,
  //       address: property.address,
  //       timestamp: formatDate(property.timestamp),
  //       price: parseFloat(property.current_price.replace(/[$,]/g, ''))
  //     }))
  //     .sort((a, b) => a.timestamp - b.timestamp); // Sort by timestamp

  //   console.log('Price History Data:', result);
  //   return result;
  //   // Filter and map the data
  //   // return filteredData
  //   //   .filter(property => selectedAddresses.has(property.address))
  //   //   .map(property => ({
  //   //     id: property.id,
  //   //     address: property.address,
  //   //     timestamp: new Date(property.timestamp),
  //   //     price: parseFloat(property.current_price.replace(/[$,]/g, ''))
  //   //   }))
  //   //   .sort((a, b) => a.timestamp - b.timestamp); // Sort by timestamp
  // }, [filteredData, selectedListingsData]);

  const priceHistoryData = useMemo(() => {
    // Get the addresses of all selected listings
    const selectedAddresses = new Set(selectedListingsData.map(listing => listing.address));
    
    return filteredData
      .filter(property => selectedAddresses.has(property.address))
      .map(property => ({
        id: property.id,
        address: property.address,
        timestamp: property.timestamp,// formatDate(property.timestamp),
        price: parseFloat(property.current_price.replace(/[$,]/g, '')),
        status: property.status
      }))
      // .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  }, [filteredData, selectedListingsData]);
  
  const generateDistinctColors = (count) => {
    const goldenRatioConjugate = 0.618033988749895;
    let h = Math.random(); // Use random start value

    return Array.from({ length: count }, (_, i) => {
      h += goldenRatioConjugate;
      h %= 1;
      const saturation = 70 + Math.random() * 30; // 70-100%
      const lightness = 45 + Math.random() * 30; // 45-55%
      return `hsl(${Math.floor(h * 360)}, ${saturation}%, ${lightness}%)`;
    });
  };

  // Group the data by address

  // const groupedData = useMemo(() => {
  //   return priceHistoryData.reduce((acc, item) => {
  //     if (!acc[item.address]) {
  //       acc[item.address] = [];
  //     }
  //     acc[item.address].push(item);
  //     return acc;
  //   }, {});
  // }, [priceHistoryData]);

  // const groupedData = useMemo(() => {
  //   const grouped = priceHistoryData.reduce((acc, item) => {
  //     if (!acc[item.address]) {
  //       acc[item.address] = [];
  //     }
  //     acc[item.address].push(item);
  //     return acc;
  //   }, {});

  //   // Sort data for each address by timestamp
  //   Object.keys(grouped).forEach(address => {
  //     grouped[address].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  //   });

  //   return grouped;
  // }, [priceHistoryData]);

  // const allDates = useMemo(() => {
  //   const dateSet = new Set(priceHistoryData.map(item => item.timestamp));
  //   return Array.from(dateSet).sort((a, b) => new Date(a) - new Date(b));
  // }, [priceHistoryData]);

  const formatTooltipLabel = (label) => {
    const date = new Date(label);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  // const CustomTooltip = ({ active, payload, label }) => {
  //   console.log(active, payload,label);
  //   if (active && payload && payload.length) {
  //     return (
  //       <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
  //         <p>{`Time: ${new Date(label).toLocaleString()}`}</p>
  //         {payload.map((entry, index) => (
  //           <p key={index} style={{ color: entry.color }}>
  //             {`${entry.name}: $${entry.value.toLocaleString()} (${entry.payload.status})`}
  //           </p>
  //         ))}
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const tooltipData = addresses.map(address => {
        const dataPoints = groupedData[address];
        const nearestPoint = dataPoints.reduce((nearest, point) => {
          return Math.abs(point.timestamp - label) < Math.abs(nearest.timestamp - label) ? point : nearest;
        });
        return { address, ...nearestPoint };
      });

      return (
        <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
          <p>{`Time: ${new Date(label).toLocaleString()}`}</p>
          {tooltipData.map((entry, index) => (
            <p key={index} style={{ color: addressColors[entry.address] }}>
              {`${entry.address}: $${entry.price.toLocaleString()} (${entry.status})`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const groupedData = useMemo(() => {
    const grouped = priceHistoryData.reduce((acc, item) => {
      if (!acc[item.address]) {
        acc[item.address] = [];
      }
      // Convert timestamp to milliseconds
      acc[item.address].push({
        ...item,
        timestamp: new Date(item.timestamp).getTime()
      });
      return acc;
    }, {});

    // Sort data for each address by timestamp
    Object.keys(grouped).forEach(address => {
      grouped[address].sort((a, b) => a.timestamp - b.timestamp);
    });

    return grouped;
  }, [priceHistoryData]);

  const allTimestamps = useMemo(() => {
    const timestamps = priceHistoryData.map(item => new Date(item.timestamp).getTime());
    return Array.from(new Set(timestamps)).sort((a, b) => a - b);
  }, [priceHistoryData]);

  const addresses = useMemo(() => 
    [...new Set(priceHistoryData.map(item => item.address))],
    [priceHistoryData]
  );
  
  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    // return new Date(tickItem).toLocaleDateString();
  };

  console.log('Price History Data:', priceHistoryData);
  console.log('Grouped Data:', groupedData);
  // Generate a color for each address
  const addressColors = useMemo(() => {
    const addresses = Object.keys(groupedData);
    const colors = generateDistinctColors(addresses.length);
    return addresses.reduce((acc, address, index) => {
      acc[address] = colors[index];
      return acc;
    }, {});
  }, [groupedData]);
  
  const buttonStyle = {
    padding: '0.5rem 1rem',
    backgroundColor: isHovered ? '#2563EB' : '#3B82F6', // Darker blue on hover
    color: 'white',
    border: 'none',
    borderRadius: '0.25rem',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease' // Smooth transition for color change
  };

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>{error}</div>;

  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#111827', color: 'white', padding: '2rem' }}>
      <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '2rem' }}>Property Listings Dashboard</h1>
      
      <Card title="Scrape New Property">
        <input
          type="text"
          value={scrapeUrl}
          onChange={(e) => setScrapeUrl(e.target.value)}
          placeholder="Enter URL to scrape"
          style={{ marginRight: '1rem', padding: '0.5rem' }}
        />
        <button
          onClick={handleScrape}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={buttonStyle}
          disabled={loading}
        >
          {loading ? 'Scraping...' : 'Scrape'}
        </button>
      </Card>

      <Card title="Refresh Properties">
        <button onClick={handleRefresh} style={{ padding: '0.5rem 1rem', backgroundColor: '#3B82F6', color: 'white', border: 'none', borderRadius: '0.25rem' }}>
          Refresh All Properties
        </button>
      </Card>

      <Card title="Filters">
        <div>
          <h3 style={{ marginBottom: '0.5rem' }}>Cities:</h3>
          {uniqueValues.cities.map(city => (
            <FilterButton
              key={city}
              label={city}
              isActive={filters.cities.includes(city)}
              onClick={() => toggleFilter('cities', city)}
            />
          ))}
        </div>
        <div style={{ marginTop: '1rem' }}>
          <h3 style={{ marginBottom: '0.5rem' }}>States:</h3>
          {uniqueValues.states.map(state => (
            <FilterButton
              key={state}
              label={state}
              isActive={filters.states.includes(state)}
              onClick={() => toggleFilter('states', state)}
            />
          ))}
        </div>
        <div style={{ marginTop: '1rem' }}>
          <h3 style={{ marginBottom: '0.5rem' }}>Statuses:</h3>
          {uniqueValues.statuses.map(status => (
            <FilterButton
              key={status}
              label={status}
              isActive={filters.statuses.includes(status)}
              onClick={() => toggleFilter('statuses', status)}
            />
          ))}
        </div>
      </Card>

      {/* <Card title="Select Listings to Visualize">
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
          {Array.from(new Set(filteredData.map(property => property.address)))
            .map((address, index) => {
              const property = filteredData.find(p => p.address === address);
              return (
                <FilterButton
                  key={property.id}
                  label={property.address}
                  isActive={selectedListings.includes(property.id)}
                  onClick={() => toggleListingSelection(property.id)}
                />
              );
            })}
        </div>
      </Card> */}

      <Card title="Select Listings to Visualize">
        <div style={{ marginBottom: '1rem' }}>
          <button 
            onClick={toggleSelectAll}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: selectAll ? '#3B82F6' : '#4B5563',
              color: 'white',
              border: 'none',
              borderRadius: '0.25rem',
              cursor: 'pointer'
            }}
          >
            {selectAll ? 'Deselect All' : 'Select All'}
          </button>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
          {Array.from(new Set(filteredData.map(property => property.address)))
            .map((address, index) => {
              const property = filteredData.find(p => p.address === address);
              return (
                <FilterButton
                  key={property.id}
                  label={property.address}
                  isActive={selectedListings.includes(property.id)}
                  onClick={() => toggleListingSelection(property.id)}
                />
              );
            })}
        </div>
      </Card>

      <Card title="Price History for Selected Listings">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#666"/>
            <XAxis 
              // dataKey="timestamp" 
              // type="category" 
              // allowDuplicatedCategory={false} 
              // dataKey="timestamp" 
              // type="category" 
              // allowDuplicatedCategory={false}
              // data={allDates}
              dataKey="timestamp" 
              type="number" 
              domain={[Math.min(...allTimestamps), Math.max(...allTimestamps)]}
              tickFormatter={formatXAxis}
              scale="time"
              ticks={allTimestamps}
            />
            <YAxis 
              dataKey="price"
              tickFormatter={(value) => `$${value.toLocaleString()}`}
            />
            {/* <Tooltip 
              labelFormatter={formatTooltipLabel}
              formatter={(value, name, props) => [
                `$${value.toLocaleString()}`,
                `${name} (${props.payload.status})`
              ]}
            /> */}
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {Object.entries(groupedData).map(([address, data]) => (
              // {addresses.map((address) => (
              <Line 
                key={address}
                // data={groupedData[address]} 
                data={data} 
                type="monotone" 
                dataKey="price" 
                name={address} 
                stroke={addressColors[address]}
                strokeWidth={2}
                dot={{ 
                  r: 4, 
                  strokeWidth: 2, 
                  stroke: addressColors[address],
                  fill: (entry) => {
                    switch(entry.status) {
                      case 'For Sale': return '#4CAF50';
                      case 'Pending': return '#FFC107';
                      case 'Sold': return '#F44336';
                      default: return '#222222';
                    }
                  }
                }}
                activeDot={{ 
                  r: 8, 
                  strokeWidth: 2,
                  stroke: addressColors[address],
                  fill: addressColors[address]
                }}
                connectNulls
              />
            ))}            
          </LineChart>
        </ResponsiveContainer>
      </Card>

      {/* <Card title="Price History for Selected Listings">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#666"/>
            <XAxis 
              dataKey="timestamp" 
              type="category" 
              allowDuplicatedCategory={false} 
            />
            <YAxis 
              dataKey="price"
              tickFormatter={(value) => `$${value.toLocaleString()}`}
            />
            <Tooltip 
              formatter={(value) => [`$${value.toLocaleString()}`, "Price"]}
              labelFormatter={(label) => `Date: ${label}`}
            />
            <Legend />
            {Object.entries(groupedData).map(([address, data]) => (
            <Line 
              key={address}
              data={data} 
              type="monotone" 
              dataKey="price" 
              name={address} 
              stroke={addressColors[address]}
              strokeWidth={2}
              dot={{ 
                r: 4, 
                strokeWidth: 2, 
                stroke: addressColors[address],
                fill: '#222222' // Dark background color
              }}
              activeDot={{ 
                r: 8, 
                strokeWidth: 2,
                stroke: addressColors[address],
                fill: addressColors[address]
              }}
            />
            ))}            
          </LineChart>
        </ResponsiveContainer>
      </Card> */}

      <Card title="Average Price and Standard Deviation">
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart data={averageData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />

            <Legend />
            <Line 
              type="monotone" 
              dataKey="avgPrice" 
              name="Average Price" 
              stroke="#8884d8"
              strokeWidth={2}
            >
              <ErrorBar 
                dataKey="stdDev" 
                width={5} 
                strokeWidth={5}
                stroke="#ff82d8" 
                direction="y"
              />
            </Line>
            <Tooltip 
              formatter={(value, name) => [
                `$${Number(value).toLocaleString()}`, 
                name === 'Average Price' ? 'Average Price' : 'Standard Deviation'
              ]}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Card>

      <Card title="Property Listings">
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'left', padding: '0.5rem' }}>Address</th>
              <th style={{ textAlign: 'left', padding: '0.5rem' }}>Price</th>
              <th style={{ textAlign: 'left', padding: '0.5rem' }}>Beds</th>
              <th style={{ textAlign: 'left', padding: '0.5rem' }}>Baths</th>
              <th style={{ textAlign: 'left', padding: '0.5rem' }}>Sqft</th>
              <th style={{ textAlign: 'left', padding: '0.5rem' }}>Status</th>
              <th style={{ textAlign: 'left', padding: '0.5rem' }}>City</th>
              <th style={{ textAlign: 'left', padding: '0.5rem' }}>State</th>
              <th style={{ textAlign: 'left', padding: '0.5rem' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
              .filter((property, index, self) => 
                index === self.findLastIndex((p) => (
                  p.address === property.address
                ))
              )
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
              .map((property) => (
                <tr key={property.id}>
                  <td style={{ padding: '0.5rem' }}>{property.address}</td>
                  <td style={{ padding: '0.5rem' }}>{property.current_price.toLocaleString()}</td>
                  <td style={{ padding: '0.5rem' }}>{property.beds}</td>
                  <td style={{ padding: '0.5rem' }}>{property.baths}</td>
                  <td style={{ padding: '0.5rem' }}>{property.sqft}</td>
                  <td style={{ padding: '0.5rem' }}>{property.status}</td>
                  <td style={{ padding: '0.5rem' }}>{property.city}</td>
                  <td style={{ padding: '0.5rem' }}>{property.state}</td>
                  <td style={{ padding: '0.5rem' }}>
                    <button onClick={() => openListingDetails(property)} style={{ padding: '0.25rem 0.5rem', backgroundColor: '#3B82F6', color: 'white', border: 'none', borderRadius: '0.25rem' }}>
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            {/* {filteredData.map((property) => (
              <tr key={property.id}>
                <td style={{ padding: '0.5rem' }}>{property.address}</td>
                <td style={{ padding: '0.5rem' }}>${property.current_price.toLocaleString()}</td>
                <td style={{ padding: '0.5rem' }}>{property.beds}</td>
                <td style={{ padding: '0.5rem' }}>{property.baths}</td>
                <td style={{ padding: '0.5rem' }}>{property.sqft}</td>
                <td style={{ padding: '0.5rem' }}>{property.status}</td>
                <td style={{ padding: '0.5rem' }}>{property.city}</td>
                <td style={{ padding: '0.5rem' }}>{property.state}</td>
                <td style={{ padding: '0.5rem' }}>
                  <button onClick={() => openListingDetails(property)} style={{ padding: '0.25rem 0.5rem', backgroundColor: '#3B82F6', color: 'white', border: 'none', borderRadius: '0.25rem' }}>
                    View Details
                  </button>
                </td>
              </tr>
            ))} */}
          </tbody>
        </table>
      </Card>

      <Card title="Telegram Connection Status">
        <p>{isConnected ? 'Connected to Telegram backend' : 'Not connected to Telegram backend'}</p>
        {connectionError && <p style={{ color: 'red' }}>{connectionError}</p>}
      </Card>

      <Card title="Current URL being processed">
        {currentUrl ? (
          <div>
            <p>Processing: {currentUrl.url}</p>
            <p>Status: {isProcessing ? 'Scraping...' : 'Waiting for next URL'}</p>
          </div>
        ) : (
          <p>No URL currently being processed</p>
        )}
      </Card>

      <Card title="Queued URLs from Telegram">
        {telegramUrls.length > 0 ? (
          <ul>
            {telegramUrls.slice(1).map((item, index) => (
              <li key={index} style={{ marginBottom: '0.5rem' }}>
                {item.url}
              </li>
            ))}
          </ul>
        ) : (
          <p>No URLs in queue</p>
        )}
      </Card>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {selectedListing && (
          <div>
            <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>{selectedListing.address}</h2>
            <p style={{ marginBottom: '1rem' }}>{selectedListing.description}</p>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginBottom: '1rem' }}>
              {selectedListing.image_paths.split(',').map((img, index) => {
              
                return (
                  <img 
                    key={index} 
                    src={`https://houseyhousey.me/scraper/${img.trim()}`} 
                    alt={`Property ${index + 1}`} 
                    style={{ maxWidth: '200px', height: 'auto' }} 
                  />
                );
              })}
            </div>
            <p>URL: 
              <a 
                href={selectedListing.url} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{
                  color: '#3B82F6', // This is a blue color, you can change it to any color you prefer
                  textDecoration: 'none', // This removes the underline
                  fontWeight: 'bold', // This makes the text bold
                  transition: 'color 0.3s ease', // This adds a smooth transition effect when hovering
                }}
                onMouseEnter={(e) => e.target.style.color = '#2563EB'} // Darker blue on hover
                onMouseLeave={(e) => e.target.style.color = '#3B82F6'} // Back to original blue when not hovering
              >
                {selectedListing.url}
              </a>
            </p>  
            <p>Price: ${selectedListing.current_price.toLocaleString()}</p>
            <p>Beds: {selectedListing.beds}</p>
            <p>Baths: {selectedListing.baths}</p>
            <p>Sqft: {selectedListing.sqft}</p>
            <p>Status: {selectedListing.status}</p>
            <p>City: {selectedListing.city}</p>
            <p>State: {selectedListing.state}</p>
            <p>Zip Code: {selectedListing.zip_code}</p>
            <p>Lot Size: {selectedListing.lot_size}</p>
            <p>Finished Sqft: {selectedListing.finished_sqft}</p>
            <p>Total Sqft: {selectedListing.total_sqft}</p>
            <p>Last Updated: {new Date(selectedListing.timestamp).toLocaleString()}</p>
            <div>
              <p>Notes:</p>
              {selectedListing.notes ? (
                <p>{selectedListing.notes}</p>
              ) : (
                <p>No notes for this property.</p>
              )}
            </div>
            <div style={{ marginTop: '1rem' }}>
                <textarea
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  placeholder="Add a new note..."
                  style={{
                    width: '100%',
                    padding: '0.5rem',
                    marginBottom: '0.5rem',
                    backgroundColor: '#374151',
                    color: 'white',
                    border: '1px solid #4B5563',
                    borderRadius: '0.25rem'
                  }}
                  rows={3}
                />
                <button
                  onClick={handleAddNote}
                  onMouseEnter={() => setIsAddNoteHovered(true)}
                  onMouseLeave={() => setIsAddNoteHovered(false)}
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundColor: isAddNoteHovered ? '#3B82F6' : 'transparent',
                    color: 'white',
                    border: '1px solid #3B82F6',
                    borderRadius: '0.25rem',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s'
                  }}
                >
                  Add Note
                </button>
                <button 
                  onClick={() => openClearNotesModal(selectedListing)}
                  onMouseEnter={() => setIsClearNoteHovered(true)}
                  onMouseLeave={() => setIsClearNoteHovered(false)}
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundColor: isClearNoteHovered ? '#EF4444' : 'transparent',
                    color: 'white',
                    border: '1px solid #EF4444',
                    borderRadius: '0.25rem',
                    cursor: 'pointer',
                    marginLeft: '0.5rem'
                  }}
                >
                    Clear Notes
                </button>
              </div>
          </div>
        )}
      </Modal>

      {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
        {selectedListing && (
          <div>
            <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>{selectedListing.address}</h2>
            <p style={{ marginBottom: '1rem' }}>{selectedListing.description}</p>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginBottom: '1rem' }}>
              {selectedListing.image_paths.split(',').map((img, index) => {
              
                return (
                  <img 
                    key={index} 
                    src={`http://localhost:3001/${img.trim()}`} 
                    alt={`Property ${index + 1}`} 
                    style={{ maxWidth: '200px', height: 'auto' }} 
                  />
                );
              })}
            </div>
            <p>URL: 
              <a 
                href={selectedListing.url} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{
                  color: '#3B82F6', // This is a blue color, you can change it to any color you prefer
                  textDecoration: 'none', // This removes the underline
                  fontWeight: 'bold', // This makes the text bold
                  transition: 'color 0.3s ease', // This adds a smooth transition effect when hovering
                }}
                onMouseEnter={(e) => e.target.style.color = '#2563EB'} // Darker blue on hover
                onMouseLeave={(e) => e.target.style.color = '#3B82F6'} // Back to original blue when not hovering
              >
                {selectedListing.url}
              </a>
            </p>  
            <p>Price: ${selectedListing.current_price.toLocaleString()}</p>
            <p>Beds: {selectedListing.beds}</p>
            <p>Baths: {selectedListing.baths}</p>
            <p>Sqft: {selectedListing.sqft}</p>
            <p>Status: {selectedListing.status}</p>
            <p>City: {selectedListing.city}</p>
            <p>State: {selectedListing.state}</p>
            <p>Zip Code: {selectedListing.zip_code}</p>
            <p>Lot Size: {selectedListing.lot_size}</p>
            <p>Finished Sqft: {selectedListing.finished_sqft}</p>
            <p>Total Sqft: {selectedListing.total_sqft}</p>
            <p>Last Updated: {new Date(selectedListing.timestamp).toLocaleString()}</p>
            <div>
              <p>Notes:</p>
              {selectedListing.notes ? (
                <p>{selectedListing.notes}</p>
              ) : (
                <p>No notes for this property.</p>
              )}
            </div>
            <div style={{ marginTop: '1rem' }}>
                <textarea
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  placeholder="Add a new note..."
                  style={{
                    width: '100%',
                    padding: '0.5rem',
                    marginBottom: '0.5rem',
                    backgroundColor: '#374151',
                    color: 'white',
                    border: '1px solid #4B5563',
                    borderRadius: '0.25rem'
                  }}
                  rows={3}
                />
                <button
                  onClick={handleAddNote}
                  onMouseEnter={() => setIsAddNoteHovered(true)}
                  onMouseLeave={() => setIsAddNoteHovered(false)}
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundColor: isAddNoteHovered ? '#3B82F6' : 'transparent',
                    color: 'white',
                    border: '1px solid #3B82F6',
                    borderRadius: '0.25rem',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s'
                  }}
                >
                  Add Note
                </button>
                <button
                  onClick={() => setIsClearNotesModalOpen(true)}
                  onMouseEnter={() => setIsClearNoteHovered(true)}
                  onMouseLeave={() => setIsClearNoteHovered(false)}
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundColor: isClearNoteHovered ? '#EF4444' : 'transparent',
                    color: 'white',
                    border: '1px solid #EF4444',
                    borderRadius: '0.25rem',
                    cursor: 'pointer',
                    marginLeft: '0.5rem'
                  }}
                >
                  Clear Notes
                </button>
              </div>
          </div>
        )}
      </Modal> */}

      <Modal isOpen={isClearNotesModalOpen} onClose={closeClearNotesModal}>
        {clearNotesListing && (
          <div>
            <h2 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '1rem' }}>Clear Notes</h2>
            <p>Are you sure you want to clear all notes for {clearNotesListing.address}?</p>
            <div style={{ marginTop: '1rem' }}>
              <button
                onClick={handleClearNotes}
                style={{
                  padding: '0.5rem 1rem',
                  backgroundColor: '#EF4444',
                  color: 'white',
                  border: 'none',
                  borderRadius: '0.25rem',
                  cursor: 'pointer',
                  marginRight: '0.5rem'
                }}
              >
                Yes, Clear Notes
              </button>
              <button
                onClick={closeClearNotesModal}
                style={{
                  padding: '0.5rem 1rem',
                  backgroundColor: 'transparent',
                  color: 'white',
                  border: '1px solid #4B5563',
                  borderRadius: '0.25rem',
                  cursor: 'pointer'
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default App;
